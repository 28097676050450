body {
  color: #fff;
  margin: 0;
  display: flex;
  background: #000;
  min-height: 100vh;
  font-family: 'Press Start 2P', sans-serif;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
}

input {
  font-family: 'Press Start 2P', sans-serif;
}

h1,
h2,
h3,
h4 {
  // this google font looks blurry when bold
  font-weight: normal;
  text-align: center;
}
